<template>
	<div class="login">
		<v-row no-gutters>
			<v-col md="6" offset-md="3">
				<v-card class="pa-2 card_login" rounded>
					<v-card-text>
						<div align="center">
							<v-img
								:src="ntlogo"
								contain
								height="auto"
								max-width="180px"
							></v-img>
						</div>
						<h1 align="center">HYDROPONIC</h1>
						<br />
						<h1 align="center" class="ml-16">SYSTEM</h1>
						<br />
						ชื่อผู้ใช้
						<v-text-field
							type="text"
							v-model="username"
							outlined
						></v-text-field>
						รหัสผ่าน
						<v-text-field
							type="password"
							v-model="password"
							outlined
						></v-text-field>
					</v-card-text>
					<center>
						<v-btn @click="clickLogin" color="primary">
							เข้าสู่ระบบ
						</v-btn>
					</center>

					<br />
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "login",
	components: {},
	data: () => ({
		username: "",
		password: "",
		ntlogo: require("../assets/ntlogo.png"),
	}),
	created: function () {
		// if (localStorage.getItem("jwt")) {
		//   this.$router.push("listfarm");
		// }
		localStorage.clear();
		this.$session.clear();
	},
	computed: {},

	watch: {},

	methods: {
		clickLogin() {
			var self = this;
			if (this.usernamee == "" || this.password == "") {
				self.$swal.fire({
					position: "center",
					icon: "error",
					title: "กรุณากรอกให้ครบถ้วน",
				});
				return;
			}
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/auth",
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					username: this.username,
					password: this.password,
				},
			};

			this.axios(config)
				.then(function (response) {
					if (response.data.success == 0) {
						self.$swal.fire({
							position: "center",
							icon: "error",
							title: response.data.error_message,
						});
						console.log(response.data.error_message);
						return;
					}
					console.log(response);
					localStorage.setItem("jwt", response.data.Token);
					self.$swal.fire({
						position: "center",
						icon: "success",
						title: "เข้าสู่ระบบแล้ว",
						showConfirmButton: false,
						timer: 1500,
					});
					self.$router.push("listfarm");
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
	},
};
</script>

<style></style>
